




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getExchangeOrderList } from '@/pages/videoGame/api'
import { testSign } from '@/pages/thirdSidePage/api'
import { getAppLanguage } from '@/common/utils'
@Component
export default class extends Vue {
  isLoading = false
  list: any = []
  @Prop()
  status!: string
  isInit = false
  statusMap: any = {
    '1': '待发货',
    '2': '待收货',
    '3': '已完成'
  }
  language = getAppLanguage()
  async getList() {
    const params = {
      status: this.status
    }
    const { data } = await getExchangeOrderList(params)
    this.list = data
    this.isLoading = false
    this.isInit = true
  }
  goodsNameTrans(str: string) {
    if (this.language == 'zh_CN') {
      return str
    } else {
      return str.replace(/金币/, ' tagga')
    }
  }
  onRefresh() {
    this.isLoading = true
    this.getList()
  }
  goDetail(item: any) {
    this.$router.push({
      name: 'orderDetail',
      query: {
        id: item.id
      }
    })
  }
  created() {
    this.getList()
  }
}
