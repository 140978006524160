




















import { Component, Vue } from 'vue-property-decorator'
import RecordList from './components/RecordList.vue'
@Component({
  components: {
    RecordList
  }
})
export default class extends Vue {
  active = 0
}
